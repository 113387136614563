import { CenterFocusStrong } from "@material-ui/icons";
import {
  container,
  title,
  main,
  whiteColor,
  mainRaised,
  primaryColor,
  brownColor,
} from "assets/jss/material-kit-pro-react.js";

const shingenStyle = (theme) => ({
  container: {
    ...container,
    zIndex: "2",
    padding: '3em 0',
    minHeight: '200px',
    [theme.breakpoints.down("lg")]: {
    },
    [theme.breakpoints.down('md')]: {
      /*padding: '0 0',*/
      minHeight: '120px',
    },
    [theme.breakpoints.down('sm')]: {
    },
    [theme.breakpoints.down('xs')]: {
      width: '96%',
    },
  },
  badge:{
    fontSize: '12px',
    lineHeight: '1.4',
    padding: '1px 10px',
  },
  textCenter: {
    textAlign: "center",
  },
  title: {
    ...title,
    color: whiteColor,
    textAlign: 'left',
    '& img':{
      maxWidth: '16em',
      width: '60%',
      minWidth: '270px',
      margin: '10vh auto',
      padding: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      '& img':{
        margin: '5vh auto',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& img':{
        margin: '0px auto',
      },
    },
  },
  title_2: {
    ...title,
    color: whiteColor,
    textAlign: 'left',
    marginBottom: '0!important',
    marginTop: '-32px',
    '& img':{
      maxWidth: '13em',
      width: '36%',
      minWidth: '250px',
      margin: '0 auto 1em auto',
      padding: '5px 15px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '0',
    },
    [theme.breakpoints.down('sm')]: {
      '& img':{
        margin: '0 auto',
      },
    },
  },
  h4_ornge_back:{
    color: '#fff',
    width: '100%',
    margin: '2em 0 1em 0',
    display: 'block',
    padding: '.25em 1em',
    textAlign: 'center',
    borderRadius: '.25em',
    backgroundColor: '#b47a39',
  },
  subtitle: {
    color: whiteColor,
  },
  h2_subtitle:{
    color: whiteColor,
    backgroundColor: primaryColor[0],
    margin: '0 auto 10vh auto', 
    boxShadow: '0 16px 24px 2px rgb(0 0 0 / 54%), 0 6px 30px 5px rgb(0 0 0 / 52%), 0 8px 10px -5px rgb(0 0 0 / 70%)',
    borderRadius: '6px',
    width: '98%',
    padding: '.5em 0',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3em',
    },
  },
  main: {
    ...main,
    ...mainRaised,
    maxWidth: '1100px',
    width: 'calc(100% - 20px)',
    margin: '-60px auto 0px',
  },
  main_s:{
    marginTop: '-60px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-30px',
    },
  },
  parallax: {
    /*height: '90vh',*/
    height: "auto",
    [theme.breakpoints.down('xs')]: {
    },
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
  /** */
  index_top: {
    margin: '0 auto',
    display: 'table',
    '& > div':{
      display: 'table-cell',
      verticalAlign: 'top',
      padding: '0 1em',
      '&:last-child': {
        width: '240px',
        position: 'relative',
        margin: '0 auto',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '& h4':{ marginTop: '0', },
    },
  },
  p1_1_text:{
    color: '#fff',
    padding: '0em .75em .15em .75em',
    borderRadius: '1em',
    backgroundColor: '#07542d',
    lineHeight: '1.2',
    position: 'absolute',
    fontWeight: 'bold',
    fontSize: '.9em',
  },
  hashi:{
    top: '106px',
    left: '89px',
  },
  kawa:{
    top: '51px',
    left: '67px',
  },
  /*section*/
  section: {
    borderTop: 'dotted 1px #333',
    marginTop: '3em',
    paddingTop: '2em',
  },
  sectionTitle: {
    display: 'table',
    verticalAlign: 'top',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: '1em',
    },
    '& > div':{
      display: 'table-cell',
      verticalAlign: 'middle',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
      '&:first-child':{
        minWidth: '17.25em',
        verticalAlign: 'top',
        [theme.breakpoints.down('sm')]: {
          margin: '1em auto',
        },
      },
      '& p':{
        margin: '0',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
    },
    '& h2':{
      padding: '.75em .5em',
      verticalAlign: 'top',
      borderRight: '8px dotted',
      margin: '0 .5em 0 0',
      minWidth: '7em',
      textAlign: 'center',
      height: '3em',
      [theme.breakpoints.down('sm')]: {
        borderRight: '0',
        borderBottom: '8px dotted',
        borderTop: '8px dotted',
        marginBottom: '.5em',
        marginRight: '0',
        padding:'.5em 0',
      },
      '& > span':{
        fontSize: '1.25em',
        lineHeight: '1em',
      },
    },
    '& img': {
      height: '4.8em',
      verticalAlign: 'top',
    },
  },
  sodan_add: {
    display: 'table',
    marginTop: '.5em',
    paddingTop: '.5em',
    width: '100%',
    '& > span':{
      display: 'table-cell',
      textAlign: 'left',
      verticalAlign: 'top',
      [theme.breakpoints.down('lg')]: {
        display: 'block',
      },
      '&:first-child': {
        padding: '0 .5em 0 0',
        fontWeight: 'bold',
        fontSize: '1.05em',
      },
      '&:last-child': {
        '& span':{
          whiteSpace: 'nowrap',
        },
      },
    },
  },
  sodan3_add_box:{
    margin: '0 auto',
    maxWidth: '336px',
    width: '25em',
    [theme.breakpoints.down('lg')]: {
      width: '18.5em',
    },
    [theme.breakpoints.down('md')]: {
      width: '25em',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  eigyoNew:{
    border: 'solid 1px #ccc',
    margin: '.5em 0',
    padding: '.5em 1em',
    fontSize: '.9em',
    display: 'inline-block',
    '& p':{
      display: 'inline-block',
      margin: '.25em 0 0 0',
      paddingLeft: '1.1em',
      paddingTop: '.25em',
      borderTop: 'dashed 1px #ccc',
      fontWeight: 'normal',
      fontSize: '.9em',
      /*whiteSpace: 'nowrap',*/
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'normal',
      },
    },
  },
  sodan2_box:{
    margin: '0 auto',
    textAlign: 'center',
    '& > div':{
      display: 'inline-block',
    },
  },
  /*card_button*/
  card_button:{
    width: 'calc(100% / 3 - 2em)',
    maxWidth: '300px',
    minWidth: '280px',
    margin: '1em',
    padding: '.25em',
    '& div': {
      width: '100%',
      height: 'auto',
    },
    '&:hover': {
      backgroundColor: primaryColor[0],
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% / 2 - 2em)',
    },
  },
  card_button_white:{
    width: '100%',
    margin: '0',
    padding: '.25em',
    display: 'block',
    color: '#000',
    whiteSpace: 'normal',
    '& div': {
      width: '100%',
      height: 'auto',
    },
    '&:hover': {
      backgroundColor: primaryColor[0],
    },
  },
  card_primary:{
    [theme.breakpoints.down('lg')]: {
      maxWidth: '600px',
      margin: '1em auto',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 0em)',
      margin: '1em auto',
    },
    '& > div':{
      [theme.breakpoints.down('sm')]: {
        padding: '1.5em 1em',
      },
    },
    '& . div':{
      padding: '1.5em',
    },
    '& h4': {
      backgroundColor: brownColor[0], 
      display: 'block',
      color: '#fff',
      padding: '.25em .25em',
      margin: '0',
      borderRadius: '.25em', 
      width: '100%', 
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.1em!important',
      },   
    },
  },
  line_annai:{
    margin: '0',
    '& > div':{
      padding:'0',
      '&:first-child':{
        paddingRight: '.5em',
        [theme.breakpoints.down('lg')]: {
          paddingRight: '0',
        },
      },
      '&:last-child':{
        paddingLeft: '.5em',
        [theme.breakpoints.down('lg')]: {
          paddingTop: '1em',
          maxWidth: '200px',
        },
      },
    },
  },
  line_tomodachi_btn:{
    backgroundColor: "#b0d1e0",
    padding: '1em',
    maxWidth: '360px',
    margin: '0 auto',
    '& ul':{
      display: 'flex',
      listStyle: 'none',
      margin: '0',
      padding: '0',
      '& > li':{
        '&:first-child':{
          width: '40%',
        },
        '&:last-child':{
          width: '60%',
        },
      },
    },
  },
  fukidashi:{
    backgroundColor: '#fff',
    borderRadius: '1em',
    marginTop: '1em',
    padding: '1em 1em .75em 1em',
  },
  line_icon_btn:{
    padding: '.15em 1em .35em 1em',
    marginTop: '.75em',
    '& img':{
      width: '40px',
    },
  },
  lineQR:{
    border: 'solid 1px #ccc',
    '& p':{
      fontSize: '.9em',
      margin: '0 auto 1em auto',
      width: '7em',
      lineHeight: '1.2',
      [theme.breakpoints.down('lg')]: {
        width: 'auto',
      },
    },
  },
  tuika:{
      width: '22em',
      margin: '1em auto',
  },
  margin_p:{
    margin: '.75em 0',
    textAlign: 'center',
    '& > p':{
      marginBottom: '0',
    },
  },
  tel:{
    fontSize: '1.8em',
    display: 'inline-block',
    fontWeight: 'bolder',
    paddingRight: '.5em',
    '& svg':{
      width: '1.5em',
      marginBottom: '-.15em',
    },
  },
  mail:{
    fontSize: '1.2em',
    display: 'inline-block',
    fontWeight: 'bold',
    paddingRight: '.5em',
    '& svg':{
      width: '1.5em',
      marginBottom: '-.2em',
    },
  },
  h4_under:{
    marginTop: '1em',
    '& p':{
      marginBottom: '1em',
    },
  },
  center_box:{
    textAlign: 'center',
    '& ul':{
      display: 'inline-block',
      margin: '0 auto .9em auto',
      textAlign: 'left',
      fontWeight: 'bold',
      paddingLeft: '0',
      '& li':{
        padding:'1px 0',
        margin:'0',
      },
    },
  },
  hr: {
    margin: '2em 0',
  },
  not_bukken:{
    textAlign: 'center',
    '& img': {
      width: '60px',
      marginTop: '1em',
    },
  },
  description: {
    textAlign: 'center',
    fontSize: '1.1em',
    width: '100%',
  },
  bukkenList: {
    margin: '.5em 0!important',
    '& a':{
      width: '100%',
      padding: '12px 0',
      '& > span > div':{
        width: '100%',
      },
    },
    '& ul':{
      display: 'table',
      width: '100%',
      margin: '0',
      listStyle: 'none',
      '& > li':{
        display: 'table-cell',
        verticalAlign: 'top',
        textAlign: 'left',
      },
    },
  },

  listImage:{
    paddingRight: '0',
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto',
      maxWidth: '120px',
    },
    '& > div':{
      marginRight: '0',
    },
  },
  listText:{
    textAlign: 'left',
    whiteSpace: 'normal',
    paddingTop: '.5em',
    '& h3':{
      margin:'.25em 0',
      fontSize: '.8em',
    },
    '& p':{
      marginTop: '-.5em',
      color: '#000',
    },
  },
  /** shosaiTitle */
  shosaiTitle: {
    display: 'table',
    verticalAlign: 'top',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    '& > div':{
      display: 'table-cell',
      verticalAlign: 'top',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
      '&:first-child':{
        [theme.breakpoints.down('sm')]: {
          margin: '1em auto',
        },
      },
      '& p':{
        margin: '0',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
    },
    '& h4':{
      padding: '.75em 1.5em .75em 1em',
      verticalAlign: 'top',
      borderRight: '8px dotted',
      margin: '0 .5em 0 0',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        borderRight: '0',
        borderBottom: '8px dotted',
        borderTop: '8px dotted',
        marginBottom: '.5em',
        marginRight: '0',
        padding:'.5em 1.25em .5em .75em',
      },
      '& > span':{
        fontSize: '1.25em',
        lineHeight: '1em',
      },
    },
    '& img': {
      height: '3.6em',
    },
    '& h2':{
      margin: '0',
      padding: '0',
    },
  },
});

export default shingenStyle;
